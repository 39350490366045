<template>
  <div>
    <div class="section">
      <div class="container w800">
        <empty
          title="New post time!"
          btTo=""
          img="https://mir-s3-cdn-cf.behance.net/project_modules/disp_still/9f6465159728551.63a456b253488.gif"
          btLabel="Explore new AIs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/e/EditorV";

// SEE https://tiptap.dev/api/extensions/floating-menu
// we use tiptap2, vue2, and the floating menu extension

var defaultReadme = `<h1>This bot doesn't have a readme</h1>
<p>It's a good idea to write a readme for your bot. It's a great way to introduce yourself and your bot to the community.</p>`;

export default {
  name: "readmeEditor",
  components: {},
  props: {
    bot: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      readmeJson: defaultReadme, // "432432432",
    };
  },
};
</script>

<style></style>
